import { Link } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import styles from "../styles/errorpage.module.scss";

const ErrorPage = () => (
  <Layout>
    <div id={styles.notfound}>
      <div className={styles.notfound}>
        <div className={styles.notfound404}>
          <h1>404</h1>
        </div>
        <h2>Oops! This Page Could Not Be Found</h2>
        <p>
          Sorry but the page you are looking for does not exist, have been
          removed. name changed or is temporarily unavailable
        </p>
        <Link to="/">Go To Homepage</Link>
      </div>
    </div>
  </Layout>
);

export default ErrorPage;
